<template>
  <div class="main-finder-wrapper">
    <div class="offers">
      <SplNuxtLink
        class="offers__link"
        :to="offersLink"
        is-external
      >
        <Lang
          by-key="offer"
          plural
          capitalize-first
        />
      </SplNuxtLink>
    </div>
    <div
      class="main-finder-wrapper__finder"
    >
      <MainFinderTabs
        :tabs="tabs"
        :value="selectedTab"
        @input="onTabClick"
      />

      <div v-show="shouldSearchSpas">
        <SpaLocationFinderSearchBar v-model="isFinderFocused" />
      </div>
      <div v-show="shouldSearchGetAways">
        <GetAwayFinderSearchBar />
      </div>
      <div v-show="shouldSearchTreatments">
        <TreatmentFinderSearchBar v-model="isFinderFocused" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MainFinderTabs from './tabs/MainFinderTabs.vue'
import SplNuxtLink from '../shared/SplNuxtLink.vue'

import SpaLocationFinderSearchBar from './SpaLocationFinderSearchBar.vue'
import GetAwayFinderSearchBar from './GetAwayFinderSearchBar.vue'
import TreatmentFinderSearchBar from './TreatmentFinderSearchBar.vue'

import { type Tab } from './tabs/Tab'

import config from '~/core/ts/server-config'
import { includesInsensitive } from '~/core/ts/util/string'
import { Promotion } from '~/core/ts/entity/Promotion'
import { translate } from '~/core/ts/util/translate'
import { useSessionLocation } from '~/core/composable/shared/useSessionLocation'

export default defineNuxtComponent({
  components: {
    MainFinderTabs,
    SplNuxtLink,
    SpaLocationFinderSearchBar,
    GetAwayFinderSearchBar,
    TreatmentFinderSearchBar,
  },
  props: {
    thanks: {
      type: Boolean,
      default: false,
    },
    defaultSelectedTab: {
      type: String,
      default: () => '',
    }
  },
  setup(props) {
    const route = useRoute()
    const runtime = useRuntimeConfig()

    const isFinderFocused = ref(false)

    const sessionLocation = useSessionLocation()

    const tabs: Tab[] = [
      { id: runtime.public.urls.spasBaseUrl, name: 'spa' },
      { id: runtime.public.urls.getAwayBaseUrl, name: 'escapada' },
      { id: config.urls.treatmentBaseUrl, name: 'treatment' },
    ]

    let index = 2
    if (
      includesInsensitive(route.path, runtime.public.urls.spasBaseUrl) ||
      includesInsensitive(route.path, config.urls.voucherPurchaseBaseUrl)
    ) {
      index = 0
    }
    if (
      includesInsensitive(route.path, runtime.public.urls.getAwayBaseUrl) ||
      includesInsensitive(route.path, config.urls.getAwayPurchaseBaseUrl)
    ) {
      index = 1
    }

    if (props.defaultSelectedTab) {
      tabs.forEach((tab, i) => {
        if (tab.name === props.defaultSelectedTab) {
          index = i
        }
      } )
    }

    const selectedTab = ref(tabs[index])

    function onTabClick(tab: Tab) {
      selectedTab.value = tab
    }

    const shouldSearchTreatments = computed(() => {
      if (selectedTab.value.id === tabs[2].id) {
        return true
      }

      return false
    })

    const shouldSearchGetAways = computed(() => {
      if (selectedTab.value.id === tabs[1].id) {
        return true
      }

      return false
    })

    const shouldSearchSpas = computed(() => {
      if (selectedTab.value.id === tabs[0].id) {
        return true
      }

      return false
    })

    const offersLink = computed(() => {
      const link = translate({
        es: '/promociones/descuentos/',
        pt: '/promocoes/descontos/',
      })

      const discount = Promotion.getDiscount()

      const location = sessionLocation.storedLocation.value
      if (location && location.urls.promotions[discount.uuid] && location.urls.promotions[discount.uuid].count >= 0) {
        return location.urls.promotions[discount.uuid].url
      }

      return link
    })

    return {
      isFinderFocused,
      tabs,
      selectedTab,
      onTabClick,

      offersLink,

      shouldSearchTreatments,
      shouldSearchGetAways,
      shouldSearchSpas,
    }
  }
})
</script>

<style lang="scss" scoped>
.offers {
  @apply hidden;

  // @apply relative;
  @apply mx-auto;
  max-width: 1085px;

  @apply text-right;
  @apply px-6;
  @apply py-1;

  @screen lg {
    @apply block;
  }

  &__link {
    // @apply absolute;

    @apply text-white;

    right: 2rem;
    top: -2rem;
  }
}
.main-finder-wrapper {
  @screen lg {
    // @apply absolute;
    @apply w-full;

    &__finder {
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
      @apply mx-auto;
      // @apply max-w-1085p;
      max-width: 1085px;
      // max-width: 68rem;
      @apply rounded-md;
      @apply border-2;
      @apply border-white;
    }
  }
}


</style>
