<template>
  <div
    v-click-outside="onBlurWrapper"
    class="get-away-finder"
    :class="{ focused }"
  >
    <div class="get-away-finder__searcher-desktop">
      <div class="get-away-finder__location-input">
        <div class="get-away-finder__title">
          <Lang
            by-key="destination"
            capitalize-first
          />
        </div>

        <input
          v-model="searchQuery"
          class="get-away-finder__location-input__input"
          type="text"
          :placeholder="searchByDestination"
          @input="onInput"
          @click="onSearchClick"
        >
      </div>

      <div class="get-away-finder__dates-input">
        <DateRangePicker
          multi-calendars
          class="get-away-finder__dates-input__input"
        />
      </div>

      <div class="get-away-finder__pax-input">
        <div class="get-away-finder__title">
          <Lang
            by-key="person"
            capitalize-first
            plural
          />
        </div>

        <div class="get-away-finder__pax-input__input">
          <PaxInlineSelector
            light
            :increment="2"
          />
        </div>
      </div>

      <div class="get-away-finder__submit-wrapper">
        <SplNuxtLink
          :to="submitURL"
          :query="submitQuery"
          is-external
          no-lang
          :is-disabled="!isSubmitButtonEnabled"
        >
          <div
            class="get-away-finder__submit-wrapper__submit"
            :class="{'active': isSubmitButtonEnabled}"
          >
            <SearchIcon
              :variant="isSubmitButtonEnabled ? 'dark' : 'neutral'"
              :size="16"
            />
          </div>
        </SplNuxtLink>
      </div>
    </div>

    <div
      class="get-away-finder__searcher-mobile"
      @click="onInput"
    >
      <p>{{ currentSearchLabel }}</p>

      <div class="get-away-finder__searcher-mobile__icon">
        <SearchIcon
          variant="dark"
          :size="16"
        />
      </div>
    </div>

    <!-- Resultado de búsqueda escritorio -->
    <div
      v-if="focused"
      class="get-away-finder__search-result only-desktop-block"
    >
      <div
        v-for="(result, i) in searchResults"
        :key="getKeyFromSearchResultItem(result, i)"
      >
        <LocationSearchResult
          :location="result.local"
          :location-parent="getLocationParent(result.local)"
          :location-country="getLocalizationCountry(result.local)"
          link-disabled
          @click="onLocationClick(result.local)"
        />
      </div>
    </div>

    <ConfigModalMobile
      v-model="focused"
      class="only-mobile"
      is-apply-button-displayed
      :apply-button-disabled="!isSubmitButtonEnabled"
      apply-button-as-link
      apply-button-link-no-lang
      :apply-button-link-url="submitURL"
      :apply-button-link-query="submitQuery"
    >
      <template #header>
        <SpalopiaIcon />
      </template>
      <div>
        <div class="get-away-finder__mobile__searcher">
          <p class="get-away-finder__mobile__searcher__title">
            <Lang
              by-key="destination"
              capitalize-first
            />
          </p>
          <input
            v-model="searchQuery"
            class="get-away-finder__mobile__input"
            type="text"
            :placeholder="searchPlaceHolder"
            link-disabled
            @input="mobileLocationInput"
            @click="mobileLocationInput"
          >
        </div>

        <div
          v-if="mobileLocationSearchFocused"
          class="get-away-finder__mobile__search-result"
        >
          <div
            v-for="(result, i) in searchResults"
            :key="getKeyFromSearchResultItem(result, i)"
          >
            <LocationSearchResult
              :location="result.local"
              :location-parent="getLocationParent(result.local)"
              :location-country="getLocalizationCountry(result.local)"
              link-disabled
              @click="onMobileLocationClick(result.local)"
            />
          </div>
        </div>

        <div class="get-away-finder__mobile__dates-input">
          <div class="get-away-finder__mobile__searcher__title">
            <Lang
              by-key="your-dates"
              capitalize-first
            />
          </div>
          <DateRangePicker
            variant="minimized"
            class="get-away-finder__mobile__dates-input__input"
          />
        </div>

        <div class="get-away-finder__mobile__pax-input">
          <div class="get-away-finder__mobile__searcher__title">
            <Lang
              by-key="person"
              capitalize-first
              plural
            />
          </div>

          <div class="get-away-finder__mobile__pax-input__input">
            <PaxInlineSelector
              light
              :increment="2"
            />
          </div>
        </div>
      </div>
    </ConfigModalMobile>
  </div>
</template>

<script lang="ts">
import { LocationCollection, Location } from '~/core/ts/entity/Location'
import PaxInlineSelector from '../shared/form/PaxInlineSelector.vue'
import SplNuxtLink from '../shared/SplNuxtLink.vue'
import LocationSearchResult from './result/LocationSearchResult.vue'

import SearchIcon from '../shared/icons/Search.icon.vue'
import SpalopiaIcon from '../shared/icons/Spalopia.icon.vue'

import DateRangePicker from '../shared/form/DateRangePicker.vue'
import ConfigModalMobile from '../shared/form/ConfigModalMobile.vue'

import { useMainFinder } from '~/core/composable/main-finder/useMainFinder'
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import {
  capitalizeFirstWord,
  includesInsensitive,
  includesWordInsensitive,
  unslashUri
} from '~/core/ts/util/string'
import { usePaxSelector } from '~/core/composable/spa-service/usePaxSelector'
import { useDateRange } from '~/core/composable/shared/useDateRange'
import { translate, translateByKey } from '~/core/ts/util/translate'
import { useLangSelector } from '~/core/composable/shared/useLangSelector'
import { useSessionLocation } from '~/core/composable/shared/useSessionLocation'

type SearchResult = { local: Location, score: number }

export default defineNuxtComponent({
  components: {
    PaxInlineSelector,
    SplNuxtLink,
    LocationSearchResult,
    SearchIcon,
    SpalopiaIcon,

    DateRangePicker,
    ConfigModalMobile,
  },
  props: {
    // "is focused" model value
    modelValue: {
      type: Boolean,
      default: false
    },
  },
  async setup(props, { emit }) {
    const {
      searchQuery,
      focused,

      onSearchClick,
      onInput,
      onBlur,

      transformSearchText,
    } = useMainFinder('locations_search', emit)

    const mobileLocationSearchFocused = ref(false)
    function mobileLocationInput() {
      mobileLocationSearchFocused.value = true
    }

    function mobileLocationBlur() {
      mobileLocationSearchFocused.value = false
    }

    // Parámetros de búsqueda
    const queryLocation = ref<Location | null>(null)

    const {
      hasSelectedPax,
      selectedPaxValue
    } = usePaxSelector(ref([]), '0')

    const {
      hasValidDateRange,
      rawDateRange,
      selectedDateRange,

      selectedDateFrom,
      selectedDateTo,
    } = useDateRange()

    const {
      currentLang,
    } = useLangSelector()

    const sessionLocation = useSessionLocation()

    const route = useRoute()
    onMounted(() => {
      // Obtenemos la localización usando la url actual.
      const cleaned = unslashUri(route.path)
      const parts = cleaned.split('_')

      let i = parts.length - 1
      while (i > 0) {
        const locationSlug = parts[i]

        for (const loc of locations) {
          if (loc.slug == locationSlug) {
            queryLocation.value = loc
            searchQuery.value = loc.label

            i = 0
            break
          }
        }

        i--
      }
    })

    const [locations, countries] = await Promise.all([
      useFetchProxy<Location[]>(
        '/api/getaway/get-location-list-with-landing',
        { method: 'post', body: { langcode: currentLang }
      }),
      useFetchProxy<Array<Location>>(
        '/api/locations/locations-by-type',
        { method: 'post', body: { type: 'country', langcode: currentLang } },
      ),
    ])

    const locationCollection = new LocationCollection([...countries, ...locations])

    function getLocationParent(loc: Location): Location | null {
      return locationCollection.getParentFrom(loc)
    }

    function getLocalizationCountry(loc: Location): Location | null {
      return locationCollection.getCountryFrom(loc)
    }

    function getKeyFromSearchResultItem(searchResult: SearchResult, index: number): string {
      if (searchResult.local) {
        return `spa-loc-search-result-${searchResult.local.uuid}`
      }

      return `spa-loc-search-result-${index}`
    }

    function onBlurWrapper() {
      onBlur()

      searchQuery.value = ''
      if (queryLocation.value) {
        searchQuery.value = queryLocation.value.label
      }
    }

    function onLocationClick(location: Location) {
      queryLocation.value = location
      searchQuery.value = location.label

      onBlurWrapper()
    }

    function onMobileLocationClick(location: Location) {
      searchQuery.value = location.label
      mobileLocationBlur()

      queryLocation.value = location
    }

    const isSubmitButtonEnabled = computed(() => {
      if (!hasValidDateRange.value) {
        return false
      }

      if (!queryLocation.value) {
        return false
      }

      if (!hasSelectedPax.value) {
        return false
      }

      return true
    })

    const submitURL = computed(() => {
      if (!isSubmitButtonEnabled.value) {
        return ''
      }

      const location = queryLocation.value as Location
      return location.urls.getAways.url
    })

    const submitQuery = computed(() => {
      if (!isSubmitButtonEnabled.value) {
        return {}
      }

      return {
        'date-range': rawDateRange.value.value as string,
        'pax': selectedPaxValue.value as string
      }
    })

    const searchLabel = translate({
      es: 'Buscar por destino y fechas',
      pt: 'Pesquisa por destino e datas',
    })
    const personTxt = capitalizeFirstWord(translateByKey('person'))
    const currentSearchLabel = computed(() => {
      if (!isSubmitButtonEnabled.value) {
        return searchLabel
      }

      let label = ''
      const location = queryLocation.value as Location
      if (location) {
        label += location.label + '. '
      }

      const from = selectedDateFrom.value
      const to = selectedDateTo.value
      if (from && to) {
        label += `(${from.getDate()} - ${to.getDate()}). `
      }

      const pax = selectedPaxValue.value
      if (pax) {
        label += `${pax} ${personTxt}s.`
      }

      return label
    })

    const searchableLocations = locations
      .filter((loc) => loc.type !== 'country')
      .filter((loc) => {
        return loc.urls.getAways.url
      })

    const searchResults = computed(() => {
      const query = transformSearchText(searchQuery.value)

      // Resultados por defecto
      if (!query.length) {
        return searchableLocations
          .filter((loc) => {
            if (loc.type !== 'community') {
              return false
            }

            const storedCountry = sessionLocation.storedLocationCountry.value
            if (storedCountry) {
              return loc.countryCode === storedCountry.countryCode
            }

            return true
          })
          .map((loc) => ({
            local: loc,
            score: 0
          }))
      }

      const finalResult: Array<SearchResult> = []
      // Búsqueda de localizaciones, cuantas más palabras de la query encontremos, más nota recibe. 'score'
      const locationResultDict: Record<string, { score: number, loc: Location }> = {}
      query.forEach((queryPart, i) => {
        searchableLocations.forEach((loc) => {
          if (includesInsensitive(loc.label, queryPart)) {
            if (!locationResultDict[loc.uuid]) {
              locationResultDict[loc.uuid] = { score: 0, loc: loc }
            }

            locationResultDict[loc.uuid].score += queryPart.length / (i + 1)
          }

          if (includesWordInsensitive(loc.label, queryPart)) {
            if (!locationResultDict[loc.uuid]) {
              locationResultDict[loc.uuid] = { score: 0, loc: loc }
            }

            locationResultDict[loc.uuid].score += queryPart.length * 2 / (i + 1)
          }
        })
      })

      // Añadimos los resultados de búsqueda de localizaciones a la lista final
      Object.values(locationResultDict)
        .filter(({score}) => !!score)
        .forEach((locResult) => {
          finalResult.push({
            local: locResult.loc,
            score: locResult.score
          })
        })

      return finalResult
    })

    const searchPlaceHolder = translateByKey('search-by-location-or-spa')
    const searchByDestination = translateByKey('search-by-location-or-spa')

    return {
      searchQuery,
      focused,

      onInput,
      onBlurWrapper,

      onSearchClick,

      // Datos para móvil
      mobileLocationSearchFocused,
      mobileLocationInput,
      mobileLocationBlur,
      onMobileLocationClick,

      currentSearchLabel,

      // selectores
      selectedDateRange,

      isSubmitButtonEnabled,
      submitURL,
      submitQuery,

      // Resultados
      searchResults,
      getKeyFromSearchResultItem,
      getLocationParent,
      getLocalizationCountry,
      onLocationClick,

      // Textos
      searchPlaceHolder,
      searchByDestination,
    }
  }
})
</script>

<style lang="scss" scoped>
.get-away-finder {
  @apply bg-white;

  @screen lg {
    @apply bg-spl-gray-1;
  }

  &__searcher-desktop {
    @apply hidden;

    @apply border-2;
    @apply border-spl-gray-1;

    @screen lg {
      @apply flex justify-between;
      @apply gap-4;
    }
  }

  &__searcher-mobile {
    @apply mb-4;
    @apply text-center;

    @apply pl-12;
    @apply pr-8;
    @apply py-4;

    @apply border-2 border-spl-gray-1;
    @apply border-b-spl-gray-2;
    @screen md {
      @apply border-b-spl-gray-1
    }

    @apply rounded-b-md;

    @apply flex;
    @apply items-center;
    @apply justify-between;

    p {
      @apply py-2;
    }

    @screen lg {
      @apply hidden;
    }
  }

  &__location-input {
    @apply py-4;
    @apply pl-12;

    flex-grow: 1;
    &__input {
      @apply w-full;
      @apply p-2;
      @apply mt-1;

      @apply bg-transparent;
      @apply border-0;
    }
  }

  &__dates-input {
    @apply py-4;

    @apply border-x-1;
    @apply border-spl-gray-2;

    flex-grow: 4;

    &__input {
      // @apply mt-1;
    }
  }

  &__pax-input {
    @apply py-4;
    @apply pl-6;

    flex-grow: 1;
    &__input {
      @apply mt-1;
      @apply flex;
    }
  }

  &__title {
    @apply font-bold;
    @apply text-spl-dark;

    @apply pl-2;
    @apply text-sm;

    @apply hidden;
  }

  @screen lg {
    &__title {
      @apply block;
    }
  }

  &__submit-wrapper {
    @apply pr-8;
    @apply py-6;

    &__submit {
      @apply bg-white;
      @apply rounded-full;

      @apply border-4;
      @apply border-white;

      // width: 100%;
      height: 100%;

      @apply ml-4;
      @apply px-6;

      @apply flex justify-center items-center;
    }
    &__submit.active:hover {
      @apply border-spl-gray-2;
    }
  }

  &__search-result {
    max-height: 65vh;
    @apply overflow-y-scroll;
  }

  &__mobile {
    &__searcher {
      @apply mt-2 mb-4;

      &__title {
        @apply font-bold;
        @apply text-spl-dark;

        @apply text-center;
      }
    }

    &__input {
      @apply w-full;
      @apply p-2;
      @apply my-2;

      @apply border border-spl-gray-1;
    }

    &__dates-input {
      @apply py-2;
    }
  }
}

.get-away-finder.focused {
  @apply bg-white;
}
</style>
