<template>
  <div
    v-if="sessionLocationEnabled"
    class="session-location-selector"
  >
    <SelectBox
      id="session-location-selector"
      variant="minified-light"
      :data="locationsTreeView"
      display-prop="label"
      item-label-as-slot
      :collapse-on-input="false"
    >
      <template #label>
        <span
          class="session-location-selector__label"
          :class="{dark}"
        >
          {{ currentSessionLocationLabel }}
        </span>
      </template>
      <template #item="{ element }">
        <div>
          <div
            v-if="hasSelectedCountry"
            class="session-location-selector__parent-item"
          >
            <SplNuxtLink
              :is-disabled="!element.url"
              no-lang
              is-external
              :to="element.url"
            >
              {{ element.location.label }}
            </SplNuxtLink>
          </div>

          <div
            v-else
            class="session-location-selector__other-country-item"
            @click="selectCountry(element.location)"
          >
            {{ element.location.label }}
          </div>


          <template
            v-for="leaf in element.children"
            :key="leaf.uuid"
          >
            <div class="session-location-selector__child-item">
              <SplNuxtLink
                :is-disabled="!leaf.url"
                no-lang
                is-external
                :to="leaf.url"
              >
                {{ leaf.location.label }}
              </SplNuxtLink>
            </div>
          </template>
        </div>
      </template>
      <template #extra-items>
        <div
          v-for="country in otherCountries"
          :key="country.uuid"
        >
          <hr>
          <div
            class="session-location-selector__other-country-item"
            @click="selectCountry(country.location)"
          >
            <DynLang
              by-key="go-to-XX"
              :params="{place: country.location.label}"
            />
          </div>
        </div>
      </template>
    </SelectBox>
  </div>
</template>

<script lang="ts">
import SplNuxtLink from '../../shared/SplNuxtLink.vue'
import SelectBox from '../../shared/form/SelectBox.vue'

import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import { useSessionLocation } from '~/core/composable/shared/useSessionLocation'
import { useContext } from '~/core/composable/shared/useContext'

import type { Location, LocationTree } from '~/core/ts/entity/Location'
import type { PromotionLanding } from '~/core/ts/entity/PromotionLanding'
import type { TreatmentLanding } from '~/core/ts/entity/TreatmentLanding'
import { langToCountryISO2 } from '~/core/ts/lang-config'

type LocationSelectorItem = {
  uuid: string,
  location: Location,
  url: string,
  children: Array<LocationSelectorItem>,
}

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    SelectBox,
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    }
  },
  async setup() {
    const sessionLocation = useSessionLocation()

    const route = useRoute()
    const context = useContext()

    const runtime = useRuntimeConfig()
    const currrentCountryCode = langToCountryISO2(runtime.public.language)

    async function getPromotionIfItIsTheCase(): Promise<PromotionLanding | null> {
      if (context.type.value !== 'promotions') {
        return null
      }

      return await useFetchProxy<PromotionLanding>(
        '/api/promotions/promotion-landing-by-uri',
        { method: 'post', body: { uri: route.path } }
      )
    }

    async function getTreatmentIfItIsTheCase(): Promise<TreatmentLanding | null> {
      if (context.type.value !== 'treatments') {
        return null
      }

      return await useFetchProxy<TreatmentLanding>(
        '/api/treatments/treatment-landing-by-uri',
        { method: 'post', body: { uri: route.path } }
      )
    }

    async function getAllCountriesTree(): Promise<Array<LocationSelectorItem>> {
      if (context.type.value === 'dynamic') {
        return []
      }

      const countries = await useFetchProxy<Array<Location>>(
        '/api/locations/locations-by-type',
        { method: 'post', body: { type: 'country' } },
      )

      const countriesTree: Array<LocationTree> = countries.map((loc):LocationTree  => {
        return {
          location: loc,
          childLocations: [],
        }
      })

      return countriesTree.map(convertLocationTreeLeafIntoLSItem)
    }

    async function getFullLocationsTree(): Promise<Array<LocationSelectorItem>> {
      if (context.type.value === 'dynamic') {
        return []
      }

      const locationTree = await useFetchProxy<Array<LocationTree>>(
        '/api/locations/location-tree-with-landing-of-context', {},
      )

      return locationTree.map(convertLocationTreeLeafIntoLSItem)
    }

    const posiblePromotion = await getPromotionIfItIsTheCase()
    const posibleTreatment = await getTreatmentIfItIsTheCase()
    const locationsTree: Array<LocationSelectorItem> = await getFullLocationsTree()
    const countriesTree: Array<LocationSelectorItem> = await getAllCountriesTree()

    function convertLocationTreeLeafIntoLSItem(branch: LocationTree): LocationSelectorItem {
      const loc = branch.location

      let url = ''

      switch (context.type.value) {
        case 'homes':
        case 'spas': {
          const landing = loc.urls.spas
          if (landing.count !== 0) {
            url = landing.url
          }
        } break

        case 'getAways': {
          const landing = loc.urls.getAways
          if (landing.count !== 0) {
            url = landing.url
          }

        } break

        case 'promotions': {
          const landingDict = loc.urls.promotions

          if (!posiblePromotion) {
            break
          }

          const landing = landingDict[posiblePromotion.tagUuid]
          if (!landing) {
            break
          }

          if (landing.count !== 0) {
            url = landing.url
          }

        } break

        case 'treatments': {
          const landingDict = loc.urls.treatments

          if (!posibleTreatment) {
            break
          }

          const landing = landingDict[posibleTreatment.tagUuid]
          if (!landing) {
            break
          }

          if (landing.count !== 0) {
            url = landing.url
          }

        } break
      }

      return {
        uuid: loc.uuid,
        location: loc,
        url,
        children: branch.childLocations.map(convertLocationTreeLeafIntoLSItem)
      }
    }

    const locationsTreeView = computed(() => {
      const storedCountry = sessionLocation.storedLocationCountry.value

      if (!storedCountry) {
        return countriesTree
      }

      let view = locationsTree
        .filter((locationItem) => locationItem.location.countryCode === storedCountry.countryCode)
        // Filtrar las "comunidades" que tengan hijos con landings válidas
        .map((loc) => {
          return {
            ...loc,
            children: loc.children.filter((loc) => !!loc.url)
          }
        })
        .filter((loc) => {
          if (loc.children.length > 0) {
            return true
          }

          return !!loc.url
        })

      const storedLoc = sessionLocation.storedLocation.value
      if (!storedLoc) {
        return view
          .sort((a, b) => a.location.label.localeCompare(b.location.label))
          .filter((loc) => loc.location.countryCode === currrentCountryCode)
          .concat(
            view.filter((loc) => loc.location.countryCode !== currrentCountryCode)
          )
      }



      return view
        .sort((a, b) => {
          if (a.uuid === storedLoc.uuid || a.uuid === storedLoc.parentUUID) {
            return -1
          }

          return a.location.label.localeCompare(b.location.label)
        })
        .filter((loc) => loc.location.countryCode === storedLoc.countryCode)
        .concat(
          view.filter((loc) => loc.location.countryCode !== storedLoc.countryCode)
        )
    })

    function selectCountry(loc: Location) {
      sessionLocation.storeCountryInSession(loc)
    }

    const hasSelectedCountry = computed(() => {
      const storedCountry = sessionLocation.storedLocationCountry.value

      return !!storedCountry
    })

    const currentSessionLocationLabel = computed(() => {
      const current = sessionLocation.storedLocation.value
      if (current) {
        return current.label
      }

      const currentCountry = sessionLocation.storedLocationCountry.value
      if (currentCountry) {
        return currentCountry.label
      }

      return 'Por país'
    })

    const otherCountries = computed(() => {
      const currentCountry = sessionLocation.storedLocationCountry.value
      if (!currentCountry) {
        return []
      }

      return countriesTree
        .filter((branch) => branch.location.countryCode !== currentCountry.countryCode)
    })

    const sessionLocationEnabled = sessionLocation.enabled

    return {
      sessionLocationEnabled,
      currentSessionLocationLabel,

      selectCountry,
      hasSelectedCountry,
      otherCountries,

      locationsTree,
      locationsTreeView,
    }
  },
})
</script>

<style lang="scss" scoped>
.session-location-selector {
  @apply pl-2;

  @apply w-full;

  @apply font-normal;
  @apply text-spl-dark;

  @screen md {
    @apply w-300p;
  }

  @screen lg {
    @apply w-400p;
  }

  &__label {
    @apply text-white;
  }

  &__label.dark {
    @apply text-spl-dark;
  }

  &__parent-item, &__child-item, &__other-country-item {
    @apply cursor-pointer;

    @apply ml-2;
    @apply my-1;
  }

  &__parent-item {
    @apply font-semibold;
  }

  &__parent-item:hover {
    @apply text-spl-primary-dark;
    @apply underline;
  }

  &__child-item {
    @apply pl-2;
    @apply py-1;
  }

  &__child-item:hover {
    @apply underline;
  }

  &__other-country-item {
    @apply py-2;
    @apply pl-1;

    @apply font-semibold;
  }

  &__other-country-item:hover {
    @apply underline;
  }
}
</style>
