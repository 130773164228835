<template>
  <SplNuxtLink
    is-external
    no-lang
    :to="url"
  >
    <div class="search-result">
      <div class="search-result__icon">
        <PlantIcon
          variant="dark"
          :size="20"
        />
      </div>
      <div class="search-result__labels">
        <div class="search-result__labels__header">
          {{ spa.name }}
        </div>
        <div class="search-result__labels__hotel">
          {{ hotel }}
        </div>
        <div class="search-result__labels__location font-bold">
          {{ city }}
        </div>
      </div>
    </div>
  </SplNuxtLink>
</template>

<script lang="ts">
import SplNuxtLink from '../../shared/SplNuxtLink.vue'
import PlantIcon from '../../shared/icons/Plant.icon.vue'

import type { SpaMiniSearch } from '~/core/ts/entity/Spa'
import { translateSilent } from '~/core/ts/util/translate'

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    PlantIcon,
  },
  props: {
    spa: {
      type: Object as PropType<SpaMiniSearch>,
      required: true
    }
  },
  setup(props) {
    const url = (() => {
      const trans = translateSilent(props.spa.texts)
      if (!trans) {
        return props.spa.uri
      }

      return trans.uri
    })()

    const city = props.spa.location.state
    const hotel = props.spa.hotelName

    return {
      url,
      city,
      hotel,
    }
  }
})
</script>

<style lang="scss" scoped>
@import url('./search-result.scss');
</style>
