<template>
  <span style="height: 16px; width: 16px">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <polyline
        id="id_106"
        points="20 12 20 22 4 22 4 12"
        :color="color"
      />
      <rect
        id="id_107"
        x="2"
        y="7"
        width="20"
        height="5"
        :color="color"
      />
      <line
        id="id_108"
        x1="12"
        y1="22"
        x2="12"
        y2="7"
        :color="color"
      />
      <path
        id="id_109"
        d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"
        :color="color"
      />
      <path
        id="id_110"
        d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"
        :color="color"
      />
    </svg>
  </span>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    // size: {
    //   type: Number,
    //   default: 40
    // },
    variant: {
      type: String,
      default: 'light' // light, christmas, dark, semi-light
    },
  },
  setup(props) {
    const color = (() => {
      if (props.variant === 'dark') {
        return '#303030'
      }

      if (props.variant === 'semi-light') {
        return '#a5a5a5'
      }

      if (props.variant === 'light') {
        return '#e5e5e5'
      }

      if (props.variant === 'christmas') {
        return '#c1a520'
      }

      // Default: light
      return '#e5e5e5'
    })()

    return {
      color,
    }
  }
})
</script>
